import { PropsWithChildren } from "react";
import { Auth0Provider } from "@auth0/auth0-react";

export const AuthProvider = ({ children }: PropsWithChildren) => {
  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN || "aqora-io.eu.auth0.com"}
      clientId={
        import.meta.env.VITE_AUTH0_CLIENT_ID ||
        "ChnbDJQ5aQEmbDZ1ytzX0WGj9ZDHOwVf"
      }
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience:
          import.meta.env.VITE_AUTH0_AUDIENCE || "https://q3as.aqora.io",
        scope:
          "openid email profile write:api_keys read:api_keys read:jobs write:jobs read:billing write:billing",
      }}
    >
      {children}
    </Auth0Provider>
  );
};
