import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createRouter } from "@tanstack/react-router";

import "@/index.css";
import { Toaster } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { routeTree } from "@/routeTree.gen";
import { AuthProvider } from "@/components/auth";
import { DarkModeProvider } from "@/components/dark_mode";
import { ApiProvider } from "@/components/api";
import { QueryClientProvider } from "@/components/query_client";
import { initSentry, sentryHandlers } from "@/lib/sentry";
import { App } from "@/app";

// Create a new router instance
const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

initSentry(router);

createRoot(document.getElementById("root")!, sentryHandlers).render(
  <StrictMode>
    <AuthProvider>
      <QueryClientProvider>
        <ApiProvider>
          <DarkModeProvider>
            <TooltipProvider>
              <App router={router} />
              <Toaster />
            </TooltipProvider>
          </DarkModeProvider>
        </ApiProvider>
      </QueryClientProvider>
    </AuthProvider>
  </StrictMode>,
);
