import { useState, PropsWithChildren, useEffect, useCallback } from "react";
import {
  DarkMode,
  Mode,
  DARK_CLASS,
  LIGHT_CLASS,
  CSS_COLORS,
  CssColors,
} from "@/lib/dark_mode";

const localStorageKey = "darkMode";

const getCssColors = () => {
  const colors: CssColors = {};
  const style = getComputedStyle(document.body);
  for (const key of CSS_COLORS) {
    colors[key] = style.getPropertyValue(`--${key.replace("_", "-")}`);
  }
  return colors;
};

const systemDarkMode = () => {
  return (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
};

export const DarkModeProvider = ({ children }: PropsWithChildren) => {
  const [isActive, setIsActive] = useState(false);
  const [mode, setMode] = useState<Mode>("auto");
  const [colors, setColors] = useState<CssColors>(getCssColors());
  const updateMode = useCallback(
    (mode: unknown) => {
      let newMode: Mode = "auto";
      switch (mode) {
        case "dark":
          setIsActive(true);
          newMode = "dark";
          break;
        case "light":
          setIsActive(false);
          newMode = "light";
          break;
        default:
          setIsActive(systemDarkMode());
          break;
      }
      setMode(newMode);
      localStorage.setItem(localStorageKey, newMode);
    },
    [setMode, setIsActive],
  );
  useEffect(() => {
    updateMode(localStorage.getItem(localStorageKey));
  }, [updateMode]);
  useEffect(() => {
    if (isActive) {
      document.documentElement.classList.remove(LIGHT_CLASS);
      document.documentElement.classList.add(DARK_CLASS);
    } else {
      document.documentElement.classList.remove(DARK_CLASS);
      document.documentElement.classList.add(LIGHT_CLASS);
    }
    setColors(getCssColors());
  }, [isActive]);
  return (
    <DarkMode.Provider
      value={{
        darkModeActive: isActive,
        darkMode: mode,
        setDarkMode: updateMode,
        colors,
      }}
    >
      {children}
    </DarkMode.Provider>
  );
};
