import {
  createRootRouteWithContext,
  Outlet,
  ScrollRestoration,
} from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { QueryClient } from "@tanstack/react-query";
import { Auth0ContextInterface } from "@auth0/auth0-react";

import { client } from "@/api/services.gen";
import { TermsDialog } from "@/logic/terms/terms";

interface RouterContext {
  auth: Auth0ContextInterface;
  client: typeof client;
  queryClient: QueryClient;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <>
      <ScrollRestoration />
      <Outlet />
      <TermsDialog />
      {import.meta.env.DEV && <TanStackRouterDevtools />}
    </>
  ),
});
