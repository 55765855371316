import { createFileRoute } from "@tanstack/react-router";

import { listApiV1ApiKeysGetOptions } from "@/api/@tanstack/react-query.gen";

import { validatePageSearch, parsePage } from "@/lib/page";

const PAGE_SIZE = 10;

export const Route = createFileRoute("/api_keys")({
  validateSearch: validatePageSearch,
  beforeLoad: async ({
    context: { client, queryClient },
    search: { page },
  }) => {
    const parsedPage = parsePage(page);
    queryClient.prefetchQuery(
      listApiV1ApiKeysGetOptions({
        client,
        query: { skip: parsedPage * PAGE_SIZE, limit: PAGE_SIZE },
      }),
    );
  },
});
