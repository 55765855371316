import { createContext, useContext, useMemo } from "react";

export const DARK_CLASS = "dark";
export const LIGHT_CLASS = "light";

export type Mode = "light" | "dark" | "auto";

export type CssColor =
  | "background"
  | "foreground"
  | "card"
  | "card_foreground"
  | "popover"
  | "popover_foreground"
  | "primary"
  | "primary_foreground"
  | "secondary"
  | "secondary_foreground"
  | "muted"
  | "muted_foreground"
  | "accent"
  | "accent_foreground"
  | "destructive"
  | "destructive_foreground"
  | "border"
  | "input"
  | "ring"
  | "radius";
export const CSS_COLORS: CssColor[] = [
  "background",
  "foreground",
  "card",
  "card_foreground",
  "popover",
  "popover_foreground",
  "primary",
  "primary_foreground",
  "secondary",
  "secondary_foreground",
  "muted",
  "muted_foreground",
  "accent",
  "accent_foreground",
  "destructive",
  "destructive_foreground",
  "border",
  "input",
  "ring",
  "radius",
];
export type CssColors = {
  background?: string;
  foreground?: string;
  card?: string;
  card_foreground?: string;
  popover?: string;
  popover_foreground?: string;
  primary?: string;
  primary_foreground?: string;
  secondary?: string;
  secondary_foreground?: string;
  muted?: string;
  muted_foreground?: string;
  accent?: string;
  accent_foreground?: string;
  destructive?: string;
  destructive_foreground?: string;
  border?: string;
  input?: string;
  ring?: string;
  radius?: string;
};

export interface DarkModeInterface {
  darkMode: Mode;
  darkModeActive: boolean;
  setDarkMode: (active: Mode) => void;
  colors: CssColors;
}

export const DarkMode = createContext<DarkModeInterface>({
  darkMode: "auto",
  darkModeActive: false,
  setDarkMode: () => {},
  colors: {},
});

export const useDarkMode = () => useContext(DarkMode);
