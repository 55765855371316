import { createContext, useContext } from "react";
import { client } from "@/api/services.gen";

export interface ApiContextInterface {
  client: typeof client;
}

export const ApiContext = createContext<ApiContextInterface>({
  client,
});

export const useApi = () => useContext(ApiContext);
