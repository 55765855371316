import { createFileRoute } from "@tanstack/react-router";

import { creditsApiV1UsersCreditsGetOptions } from "@/api/@tanstack/react-query.gen";

export const Route = createFileRoute("/billing")({
  beforeLoad: async ({ context: { client, queryClient } }) => {
    queryClient.prefetchQuery(creditsApiV1UsersCreditsGetOptions({ client }));
  },
});

