import { useEffect } from "react";
import { RouterProvider, AnyRouter } from "@tanstack/react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";

import { Spinner } from "@/components/ui/spinner";
import { useApi } from "@/lib/api";

interface Props<T> {
  router: T;
}

export const App = <T extends AnyRouter>({ router }: Props<T>) => {
  const auth = useAuth0();
  const { client } = useApi();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (auth.isLoading) {
      return;
    }
    if (!auth.isAuthenticated) {
      auth.loginWithRedirect();
    }
  });
  if (auth.isLoading) {
    return (
      <div className="grid place-items-center w-screen h-screen py-8">
        <Spinner fade />
      </div>
    );
  }
  return (
    <RouterProvider router={router} context={{ auth, client, queryClient }} />
  );
};
