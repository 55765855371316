import { createFileRoute } from "@tanstack/react-router";

import {
  readApiV1JobsSlugGetOptions,
  bestApiV1JobsSlugBestGetOptions,
  iterationsApiV1JobsSlugIterationsGetOptions,
  costApiV1JobsSlugCostGetOptions,
  circuitApiV1JobsSlugCircuitGetOptions,
  readRequestApiV1JobsSlugRequestGetOptions,
} from "@/api/@tanstack/react-query.gen";

export const Route = createFileRoute("/jobs/$slug")({
  beforeLoad: async ({
    context: { client, queryClient },
    params: { slug },
  }) => {
    queryClient.prefetchQuery(
      readApiV1JobsSlugGetOptions({ client, path: { slug } }),
    );
    queryClient.prefetchQuery(
      readRequestApiV1JobsSlugRequestGetOptions({ client, path: { slug } }),
    );
    queryClient.prefetchQuery(
      circuitApiV1JobsSlugCircuitGetOptions({ client, path: { slug } }),
    );
    queryClient.prefetchQuery(
      bestApiV1JobsSlugBestGetOptions({ client, path: { slug } }),
    );
    queryClient.prefetchQuery(
      costApiV1JobsSlugCostGetOptions({ client, path: { slug } }),
    );
    queryClient.prefetchQuery(
      iterationsApiV1JobsSlugIterationsGetOptions({
        client,
        path: { slug },
        query: { skip: 0, limit: 0 },
      }),
    );
  },
});
