import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ChevronDown, ChevronUp } from "lucide-react";
import { toast } from "sonner";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Button } from "@/components/ui/button";

import {
  termsApiV1UsersTermsGetOptions,
  termsApiV1UsersTermsGetQueryKey,
  acceptTermsApiV1UsersAcceptTermsPostMutation,
} from "@/api/@tanstack/react-query.gen";
import { useApi } from "@/lib/api";

export const TermsDialog = () => {
  const { client } = useApi();
  const queryClient = useQueryClient();
  const terms = useQuery(termsApiV1UsersTermsGetOptions({ client }));
  const acceptTerms = useMutation({
    ...acceptTermsApiV1UsersAcceptTermsPostMutation(),
    onSuccess: () => {
      toast.success("Terms accepted");
      queryClient.invalidateQueries({
        queryKey: termsApiV1UsersTermsGetQueryKey(),
      });
      setShowDialog(false);
    },
    onError: () => {
      toast.error(
        "Something went wrong accepting terms. Try again later or contact support.",
      );
    },
  });
  const [showDialog, setShowDialog] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  useEffect(() => {
    if (!terms.data) {
      return;
    }
    const accepted = terms.data?.accepted
      ? new Date(terms.data.accepted)
      : undefined;
    const updated = new Date(terms.data.updated);
    if (!accepted || updated > accepted) {
      setShowDialog(true);
    }
  }, [terms.data]);

  if (!showDialog) {
    return null;
  }

  return (
    <AlertDialog open={true}>
      <AlertDialogContent className="w-prose">
        <AlertDialogHeader>
          <AlertDialogTitle>Version αlpha</AlertDialogTitle>
          <AlertDialogDescription asChild>
            <div className="pt-2">
              Q3AS is still under active development. This version is available
              as an alpha release, and there are no guarantees of stability or
              data retention. Please read the Terms of Service below. By
              continuing, you agree to the terms.
              <Collapsible open={showTerms} onOpenChange={setShowTerms}>
                <CollapsibleTrigger asChild>
                  <div className="flex flex-row items-center mt-2 hover:cursor-pointer">
                    <span className="pr-2">Terms of Service</span>
                    <Button size="sm" variant="ghost">
                      {showTerms ? (
                        <ChevronUp className="w-4 h-4" />
                      ) : (
                        <ChevronDown className="w-4 h-4" />
                      )}
                    </Button>
                  </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <div className="h-96 overflow-y-auto p-4 my-4 bg-muted text-muted-foreground whitespace-pre-line rounded-md">
                    {terms.data?.text || ""}
                  </div>
                </CollapsibleContent>
              </Collapsible>
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction
            disabled={acceptTerms.isPending}
            onClick={() => acceptTerms.mutate({ client })}
          >
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
