import { useState, useCallback } from "react";

export interface PageSearch {
  page?: number;
}

export const parsePage = (page: unknown): number => (Number(page) || 1) - 1;

export const validatePageSearch = (
  search: Record<string, unknown>,
): PageSearch => {
  return {
    ...search,
    page: search.page ? Number(search.page) : undefined,
  };
};

export const usePage = () => {
  const [page, setPageInternal] = useState(
    parsePage(new URLSearchParams(window.location.search).get("page")),
  );
  const setPage = useCallback(
    (page: number) => {
      setPageInternal(page);
      const search = new URLSearchParams(window.location.search);
      if (page > 0) {
        search.set("page", (page + 1).toString());
      } else {
        search.delete("page");
      }
      let searchString = search.toString();
      searchString = searchString ? `?${searchString}` : "";
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}${searchString}`,
      );
    },
    [setPageInternal],
  );
  return [page, setPage] as const;
};
