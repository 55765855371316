import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { formatRelative } from "date-fns";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatRelativeDate(date: string | string | Date) {
  const formatted = formatRelative(new Date(date), new Date());
  return formatted.charAt(0).toUpperCase() + formatted.slice(1);
}
