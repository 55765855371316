import { PropsWithChildren } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { ApiContext } from "@/lib/api";
import { client } from "@/api/services.gen";

export const ApiProvider = ({ children }: PropsWithChildren) => {
  const { getAccessTokenSilently } = useAuth0();
  if (import.meta.env.VITE_API_URL) {
    client.setConfig({
      baseUrl: import.meta.env.VITE_API_URL,
    });
  }
  client.interceptors.request.use(async (request) => {
    const accessToken = await getAccessTokenSilently();
    request.headers.set("Authorization", `Bearer ${accessToken}`);
    return request;
  });
  return (
    <ApiContext.Provider value={{ client }}>{children}</ApiContext.Provider>
  );
};
